import React, { Component } from 'react';
import axios from 'axios';

class Home extends Component{

  constructor(props){
    super(props);
    this.state = {
      country : []
    }
  }

  componentDidMount(){
    this.fetchCountry();
  }

  fetchCountry = async () =>{
    let url = "https://react.augusttech.website/getdatas";
    // GET request for remote image in node.js
    axios({
      method: 'get',
      url: url,
      //responseType: 'json'
    }).then((response) =>{
      if(response.status===200 || response.status === 201){
          let country = response.data;
          country = country.map((item,index) =>{
              return {sino:item.data_id,sdate:item.sdate,road:item.road,vreg:item.vreg,vcls:item.vcls,amt:item.amt,vst:item.vst,edate:item.edate}
          });
          console.log(country);
          this.setState({
            country
          });

      }else{
        alert('Something happened, please check!')
      }
      //response.data.pipe(fs.createWriteStream('ada_lovelace.jpg'))
    });
  }
  renderCountries = () =>{
    let {country} = this.state;
    return country.map((item,index)=>{
      return (
        <tr key={index}>
          <td>{item.sino}</td>
          <td><input type='text' className='form-control form-control-sm'  value={item.sdate} name='sdate[]' /></td>
          <td><input type='text' className='form-control form-control-sm' value={item.road} name='road[]' /></td>
          <td><input type='text' className='form-control form-control-sm' value={item.vreg} name='vreg[]' /></td>
          <td><input type='text' className='form-control form-control-sm' value={item.vcls} name='vcls[]' /></td>
          <td><input type='text' className='form-control form-control-sm' value={item.amt} name='amt[]' /></td>
          <td><input type='text' className='form-control form-control-sm' value={item.vst} name='vst[]' /></td>
          <td><input type='text' className='form-control form-control-sm' value={item.edate} name='edate[]' /></td>
        </tr>
      )
    });
  }
  renderTable = () =>{
    return(
      <div className="table-responsive">
        <table className="table table-sm table-bordered table-striped table-hover">
          <thead className="thead-light">
            <tr>
              <th className="text-primary">Si.No</th>
              <th className="text-danger">SDate</th>
              <th className="text-danger">Road Name</th>
              <th className="text-success">Vehicle Reg</th>
              <th className="text-black">Vehicle Class</th>
              <th className="text-danger">Amount</th>
              <th className="text-danger">Vehicle State</th>
              <th className="text-danger">EDate</th>
            </tr>
          </thead>
          <tbody >
            {this.renderCountries()}
          </tbody>
        </table>
      </div>   
    )
  }
 
    render(){
      return(
        <div className="container" >
          <div className="row align-items-center" style={{height:"100%"}}>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xl-12" style={{height:"100%",padding:"0",margin:"0 0 100px 0"}}>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xl-12  float-left">
                {this.renderTable()}
              </div>
            </div>
          </div>
        </div>
      )
    }  
    
}
export default Home;