import React, { Component } from 'react';

class Card extends Component{
    constructor(props){
        super(props);
       // console.log(props);
    }
    render(){
        return(
            <div className="card" style ={{height:"100%"}}>
              <div className="card-body">
                {this.props.children}
              </div>
            </div>
        )
    }
}

export default Card;