import React, { Component } from 'react';

//Material ui icons
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';

class Header extends Component{
    constructor(props){
        super(props);
       // console.log(props);
    }
    renderNavbar = () => {
        return(
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <a className="navbar-brand" href="/">CoronaTracker</a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
              </li>
              <li className="nav-item active">
                <a className="nav-link" href="/country">Country </a>
              </li>
              <li className="nav-item float-right">
                Share <FacebookIcon></FacebookIcon> <TwitterIcon></TwitterIcon>
              </li>
            </ul>
            
          </div>
        </nav>
        )
      }
    render(){
        return(
            <div>
              
                {this.renderNavbar()}
               
            </div>
        )
    }
}

export default Header;