import logo from './logo.svg';
import './App.css';
import React, { Component } from 'react';

//import home component
import Home from './components/home/home';

//routing

import Country from './components/country/country';
import Header from './components/header/header';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  
  
} from "react-router-dom";

class App extends Component{

  constructor(props){
    super(props);
  }
  renderNav = () =>{
    return(
      <Header />
    )
  }
  render(){
    return (
        <Router basename={"/"}>
        {this.renderNav()}
        <br></br>
          <div>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/country">
              <Country />
            </Route>
          </Switch>
        </div>
      </Router>
      )
  }
    
}



export default App;
