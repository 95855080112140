import React, { Component } from "react";

//Apexchart 
import Chart from "react-apexcharts";
//Card Component child
import Card from "../card/card";


class Country extends Component{

    constructor(props){
        super(props);
    }
    
    renderMainTemplate = () =>{
        let series = [500,8000];
        let total = series.reduce((a,b) => a+b);
        let deadPercent = series[0] / total * 100;
        let options = {
          legend:{
            show:false
          },
          dataLabels:{
            enabled:false
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              endAngle: 360,
              expandOnClick: false,
              offsetX: 0,
              offsetY: 0,
              customScale: 1,
              dataLabels: {
                  offset: 0,
                  minAngleToShowLabel: 10
              }, 
              donut: {
                size: '90%',
                background: 'transparent',
                labels: {
                  show: false,
                }
              },      
            }
          },
          fill:{
            colors:['rgb(205, 92, 92)','rgb(213, 216, 220)']
          },
          tooltip:{
            enabled:false
          },
          states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                }
            },
            hover: {
                filter: {
                    type: 'lighten',
                    value: 0.15,
                }
            },
            active: {
                filter: {
                    type: 'darken',
                    value: 0.35,
                }
            },
          },
          chart: {
            selection: {
              enabled: false,
            }
          },
          title: {
            text: deadPercent.toFixed(1)+" %",
            align: 'center',
            margin: 0,
            offsetX: 0,
            offsetY: 40,
            floating: true,
            style: {
              fontSize:  '24px',
              fontWeight:  'bold',
              fontFamily:  undefined,
              color:  '#263238'
            },
          },
          subtitle: {
            text: 'of Total Cases',
            align: 'center',
            margin:  0,
            offsetX: 0,
            offsetY: 77,
            floating: true,
            style: {
              fontSize:  '0.8em',
              fontWeight:  'normal',
              fontFamily:  undefined,
              color:  '#9699a2'
            },
          }
        };
    return (
        <div className="row">
            <div className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-6 ">
            <Card>
                <div className="row" style = {{height:"100%"}}>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h5 className="card-title">Special title treatment</h5>
                    <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                    <a href="/" class="btn btn-primary">Go somewhere</a>
                </div>
                </div>
            </Card>
            </div>
            <div className="col-lg-3 col-md-3 col-xl-3 col-sm-12 col-xs-3 mb-10">
            <div className="card" style ={{height:"100%"}}>
                <div className="card-body" style={{padding:0}}>
                <div className="row align-items-center" style = {{height:"100%"}} >
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" >
                    <Chart options={options} series={series} type="donut" width="100%" height="150 px" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" >
                    <p>Faculity Rate</p>
                    </div>
                </div>
                
                </div>
            </div>
            </div>
            <div className="col-lg-3 col-md-3 col-xl-3 col-sm-12 col-xs-3mb-10 mb-10">
            <div className="card"  style ={{height:"100%"}}>
                <div className="card-body">
                <div class="row align-items-center" style = {{height:"100%"}}>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">

                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <p>Recovery Rate</p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    )
    }
    render(){
        return(
          <div>
            <div className="container padding-10">
              {this.renderMainTemplate()}
            </div>
          </div>
        )
    }
}
export default Country;